<template>
<!--        :delay="{
            hide:300
        }"-->
    <v-popover
        trigger="hover"
        placement="left-start"
        offset="1"
        :auto-hide="true"
        class="submenu"
        popoverInnerClass="submenu__body"
    >
        <span class="submenu__icon tooltip-target"/>

        <template slot="popover" class="submenu__content"  v-if='get'>
            <ul class="submenu__links">
                <li v-if="current_user.is_admin" class="submenu__item">
                    <a @click="editIdea">Редактировать</a>
                </li>
                <li class="submenu__item" >
                    <a href="javascript:void(0)"  @click="printSection">Печать</a>
                </li>
                <li class="submenu__item"  v-if="current_user.is_admin">
                    <a href="javascript:void(0)"  @click="deleteIdea">Удалить</a>
                </li>
            </ul>
        </template>
    </v-popover>

</template>

<script>
    import {mapState} from "vuex";
    import session from '../../api/session'
    export default {
        mounted() {
            window.addEventListener('afterprint', (event) => {
                this.get = true;
            });
        },
        data(){
          return{
              get: true,
          }
        },
        name: 'Submenu',
        computed: {
            ...mapState('default_data', [
                'current_user'
            ])
        },
        props: {
            ideaId: {
                required: true
            }
        },
        methods: {
            async deleteIdea(){
                this.$swal({
                    customClass: {
                        confirmButton: 'btn btn-lg btn-alt-success m-5',
                        cancelButton: 'btn btn-lg btn-alt-danger m-5'
                    },
                    title: 'Удалить идею?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Да, удалить!',
                    cancelButtonText: 'Отменить'
                }).then(
                    async (value) => {
                        if (value.value) {
                            const swal = this.$swal({
                                title: 'Удаление...',
                                icon: 'info',
                                showConfirmButton: false,
                                allowOutsideClick: false,
                                html: '<i class="fa fa-2x fa-cog fa-spin"></i>'
                            })
                            const data = await  session.delete(`/api/v1/idea/${this.ideaId}/`)
                            swal.close()
                            if (data.status === 204){
                                await this.$router.push('/idea')
                            }
                        }
                    })
            },
            editIdea() {
                const IdeaUpdate = () => import('./modals/IdeaUpdate');
                this.$modal.show(IdeaUpdate, {
                    ideaId: this.ideaId
                }, {
                    name: 'idea-update',
                    adaptive: true,
                    maxWidth: 824,
                    width: '100%',
                    height: 'auto'
                })
            },
             printSection() {
                this.get = false;
                this.$nextTick(()=>{
                    window.print({iframe: false,});
                })
                 // this.get = true;
                // console.log(this.$refs.popover.hide());
                // this.$htmlToPaper("page-print");
                // let lPage = $('#page-print');
                // let pageCls = lPage.prop('class');
                // lPage.prop('class', '');
                // let originalTitle = document.title;
                // document.title = "";
                // Print the page
                // document.title = originalTitle;
                // lPage.prop('class', pageCls);
            }
        }
    }
</script>

<style lang="sass" scoped>
    @import "#sass/v-style"
    .trigger
        display: flex !important

    .submenu
        cursor: pointer

        &:hover .submenu__icon
            background-image: $rubric-menu-icon-active

        &__icon
            display: flex
            background-image: $rubric-menu-icon
            background-repeat: no-repeat
            background-position: center
            background-size: contain
            width: 18px
            height: 18px

        &__links
            padding: 0
            list-style-type: none
            width: 100%
            margin-bottom: 0

        &__item
            &:hover a
                color: rgba(87, 87, 87, 0.7)

            a
                padding: 5px 15px
                height: 100%
                width: 100%
                display: flex
                color: rgba(87, 87, 87, 0.9)
                cursor: pointer

                &:first-child
                    padding-top: 10px

                &:last-child
                    padding-bottom: 10px
</style>

