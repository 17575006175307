import { render, staticRenderFns } from "./IdeaSubmenu.vue?vue&type=template&id=7c756702&scoped=true&"
import script from "./IdeaSubmenu.vue?vue&type=script&lang=js&"
export * from "./IdeaSubmenu.vue?vue&type=script&lang=js&"
import style0 from "./IdeaSubmenu.vue?vue&type=style&index=0&id=7c756702&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c756702",
  null
  
)

export default component.exports